import React, { useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.modal__content')) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <>
      {isOpen && (
        <div className='modal'>
          <div className="modal__content">
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
