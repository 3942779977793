import React from 'react';
import NavBar from '../../components/NavBar/NavBar';

const Header = () => {

  return (
    <header className='header'>
      <NavBar/>
      <h1 id='quote'>
        Tarvitseeko sun tilaisuus vetonaulan?<br/> – Mä voin tulla vasaroimaan!
      </h1>
    </header>
  )
}

export default Header;