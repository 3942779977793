import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import { Link } from 'react-router-dom';

const Pricing = () => {
  return (
    <div className="pricing">
      <NavBar/>
      
      <div className="pricing__content">
        <h1 className="pricing__content__title">Taikurin hinta</h1>
        <p className="pricing__content__text">
          Keskimääräiset kustannukset esiintymisille vaihtelevat yleensä 400–1000 euron välillä. 
          Esityksen lopulliseen hintaan vaikuttavat useat tekijät, kuten itse esityksen kesto, 
          tapahtumapaikan sijainti ja esityksen sisältö. Vaikka toimipaikkani sijaitsee Tampereella, 
          olen innokas tekemään esiintymisiä ympäri Suomea. Matkakustannuksilla on hieman vaikutusta 
          hintaan.
          <br/>
          <br/>
          Pyrin tarjoamaan mahdollisimman kilpailukykyisen kokonaisuuden, jotta voimme yhdessä 
          luoda unohtumattoman ja taianomaisen hetken juhliinne. Odotan innolla mahdollisuutta 
          tuoda taikatemppuni lähemmäs teitä!
          <br/>
          <br/>
          Suunnitellaan yhdessä juuri sopiva kokonaisuus tilaisuuteenne!
          <br/>
          <Link className="pricing__content__text__link" to="/tarjouspyyntö">Pyydä tarjous!</Link>
        </p>
        <div className="pricing__content__image"></div>
      </div>
    </div>
  );
};

export default Pricing;
