import React, { useState } from 'react';
import { getS3VideoUploadUrl, saveVideoDataToDatabase, uploadVideo } from '../../utils/api';
import Modal from '../../components/Modal/Modal';

const VideoUploader = ({ isOpen, onClose, onUpload }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(file);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const getPresignedUrl = async (fileName) => {
    try {
      const response = await getS3VideoUploadUrl(fileName);
      return response;
    } catch (error) {
      console.error('Error getting S3 presigned URL', error);
      throw error; // or handle it accordingly
    }
  };

  const uploadToS3 = async (uploadUrl, file) => {
    try {
      await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'video/*',
        },
        body: file,
      });
    } catch (error) {
      console.error('Error uploading video to S3', error);
      throw error; // or handle it accordingly
    }
  };

  const handleUpload = async () => {
    try {
      if (!videoFile) {
        console.error('No video selected for upload');
        return;
      }

      setLoading(true); // Set loading state to true

      const response = await getPresignedUrl(videoFile.name);
      const { uploadUrl, newFileName } = response;

      if (uploadUrl) {
        console.log(uploadUrl);
        console.log(newFileName);
        await uploadToS3(uploadUrl, videoFile);

        // Save video data to the database
        await saveVideoDataToDatabase({
          fileName: newFileName,
          originalVideoName: videoFile.name,
          title,
        });

        // Notify the parent component that upload is complete
        onUpload();
      } else {
        console.error('Failed to get S3 presigned URL');
        // Handle the failure to get the presigned URL as needed
      }

      setLoading(false); // Set loading state back to false
      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error uploading video', error.message);
      setLoading(false); // Reset loading state in case of an error
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='video-uploader-form-group'>
        <label htmlFor='videoFile'>Valitse video</label>
        <input type='file' id='videoFile' accept='video/*' onChange={handleFileChange} />
      </div>
      <div className='video-uploader-form-group'>
        <label htmlFor='title'>Video otsikko</label>
        <input type='text' id='title' value={title} onChange={handleTitleChange} />
      </div>
      <div>
        <button onClick={handleUpload} disabled={loading}>
          {loading ? 'Lisätään...' : 'Lisää video'}
        </button>
        <button onClick={onClose} disabled={loading}>
          Peruuta
        </button>
      </div>
    </Modal>
  );
};

export default VideoUploader;
