import React, { useEffect, useState } from 'react';
import { getVideos, deleteVideo, uploadVideo } from '../../utils/api';
import { S3_URL } from '../../config';
import NavBar from '../../components/NavBar/NavBar';
import Modal from '../../components/Modal/Modal';
import VideoUploader from './VideoUploader';
import { useAuth } from '../../auth/AuthContext';

const VideoGallery = () => {
  const { user } = useAuth();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const data = await getVideos();
      setVideos(data);
    } catch (error) {
      console.error('Error fetching videos', error.message);
    }
  };

  const openDeleteModal = (video) => {
    setSelectedVideo(video);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedVideo(null);
    setIsDeleteModalOpen(false);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDelete = async (videoId) => {
    try {
      await deleteVideo(videoId);
      // Update the state or refetch the videos after deletion
      fetchVideos();
    } catch (error) {
      console.error('Error deleting video', error.message);
    }
    closeDeleteModal();
  };

  const handleUpload = async (video) => {
    try {
      await uploadVideo(video);
      // Update the state or refetch the videos after upload
      fetchVideos();
    } catch (error) {
      console.error('Error uploading video', error.message);
    }
    closeUploadModal();
  };

  return (
    <>
      <NavBar />
      {user && 
        <div className='video-upload-btn-container'>
          <button className='video-upload-btn' onClick={openUploadModal}>Lisää Video</button>
        </div>
      }
      
      <div className='video-gallery'>
        {videos.length > 0 ? (
          videos.map((video) => (
            <div key={video._id} className='video-gallery__video'>
              {user &&
                <button
                  className='video-gallery__delete-btn'
                  onClick={() => openDeleteModal(video)}
                >
                  Poista
                </button>
              }
              <video src={`${S3_URL}/${video.videoName}`} controls />
              <h2>{video.title}</h2>
            </div>
          ))
        ) : (
          <p>Ladataan videoita...</p>
        )}
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      >
        <p>Poista video?</p>
        <div>
          <button onClick={() => handleDelete(selectedVideo._id)}>Kyllä</button>
          <button onClick={closeDeleteModal}>Ei</button>
        </div>
      </Modal>
      
      <VideoUploader
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={fetchVideos}
      />
    </>
  );
};

export default VideoGallery;
