import React, { useRef, useState } from 'react';
import { addGigRequest, verifyCaptcha } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    summary: '',
    additionalInfo: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const recaptcha = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setSubmitError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    try {
      // Check if all required fields are filled
      const requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'summary'];
      const missingFields = requiredFields.filter(field => !formData[field]);
      if (missingFields.length > 0) {
        throw new Error('Kaikki tähdellä merkityt kentät ovat pakollisia!');
      }

      if (!captchaValue) {
        throw new Error('Suorita CAPTCHA todistaaksesi, että et ole botti!');
      }

      setSubmitting(true);
      setSubmitError(null);

      // Verify the captcha token
      const captchaResponse = await verifyCaptcha(captchaValue);
      if (!captchaResponse.success) {
        throw new Error('CAPTCHA-tilan vahvistus epäonnistui');
      }

      // Proceed with form submission
      const data = await addGigRequest(formData);
      setSubmitSuccess(true);
      navigate('/tarjouspyyntö-lähetetty');
    } catch (error) {
      console.error(error);
      setSubmitError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="contact-form__form-group">
        <label htmlFor="firstName">Etunimi: *</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          maxLength={'50'}
          className="contact-form__form-group__input"
        />
      </div>
      <div className="contact-form__form-group">
        <label htmlFor="lastName">Sukunimi: *</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          maxLength={'50'}
          className="contact-form__form-group__input"
        />
      </div>
      <div className="contact-form__form-group">
        <label htmlFor="email">Sähköpostiosoite: *</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          maxLength={'100'}
          className="contact-form__form-group__input"
        />
      </div>
      <div className="contact-form__form-group">
        <label htmlFor="phoneNumber">Puhelinnumero: *</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          maxLength={'20'}
          className="contact-form__form-group__input"
        />
      </div>
      <div className="contact-form__form-group">
        <label htmlFor="summary">Tiivistelmä keikasta (Päivämäärä, sijainti, kesto, edustamasi taho etc.): *</label>
        <textarea
          id="summary"
          name="summary"
          value={formData.summary}
          onChange={handleChange}
          rows={5}
          maxLength={'500'}
          className="contact-form__form-group__input"
        />
      </div>
      <div className="contact-form__form-group">
        <label htmlFor="additionalInfo">Muuta, esim. kysymyksiä:</label>
        <textarea
          id="additionalInfo"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          rows={5}
          maxLength={500}
          className="contact-form__form-group__input"
        />
      </div>

      <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />

      {submitError && <div className="contact-form__error-message">{submitError}</div>}
      {submitSuccess && <div className="contact-form__success-message">Tarjouspyyntö lähetetty onnistuneesti!</div>}

      <button type="submit" className="contact-form__submit-button" disabled={submitting}>
        {submitting ? 'Lähetetään...' : 'Lähetä'}
      </button>
    </form>
  );
};

export default ContactForm;
