import React from 'react';
import Header from './Header';
import Footer from '../../components/Footer/Footer';
import ContactForm from './ContactForm';

const Home = () => {

  return (
    <div className='home'>
      <Header/>

      <section className='home__section home__section__1'>
        <p>
          Juhlat tai kenties jokin muu tilaisuus tulossa?
          Valmistelut kesken – ohjelmakin vielä suunnitteilla.
          Unohda stressi ja anna taikuuden auttaa!
          <br/><br/>
          Mistä juhlista sitten kyse onkaan, löydämme yhdessä sopivan esityskokonaisuuden!
          <br/><br/>
          Tehdäänkö illastasi ikimuistoinen?
        </p>
      </section>

      <section className='home__section home__section__2'>
        <a href='#tarjouspyyntö'>Pyydä tarjous</a>
        <p>
          Tarjolla mieltäsi kutkuttavaa hulvattomuutta tai jännitystä nostattavaa 
          mysteerisyyttä unohtamatta tietenkin rentoa tunnelmaa. Oli sitten 
          tilaisuutesi suuri tai pienimuotoinen, voimme sopia sinulle mieluisen 
          ohjelmanumeron.
        </p>
      </section>

      <section className='home__section home__section__3'>

        <div>
          <div>
            <h2>Lähitaikuus:</h2>

            <p>
              Lähitaikuudessa pääset seuraamaan taikuutta lähempää kuin koskaan!

              Voit tuntea taikuuden aivan hyppysissäsi, sillä pääset seuraamaan ihmeitä taikurin vierestä.

              Lähitaikuus soveltuu pienille ryhmille kerrallaan, joten se on oivallinen pöydästä pöytään kiertelyyn.
            </p>
          </div>
          
          <img src={process.env.PUBLIC_URL + '/images/fire.png'}/>
        </div>
          
        <div>
          <div>
            <h2>Salonkitaikuus:</h2>
            <p>
              Salonkitaikuus on lähitaikuuden ja näyttämötaikuuden välimuoto, joka tuo taikuuden olohuoneeseesi tai vaikka baariin.

              Pääset nauttimaan mieltä kutkuttavista mysteereistä höystettynä huumorilla.

              Esitetään kaikille yhtä aikaa. Soveltuu erinomaisesti pieniin juhliin ja tilaisuuksiin. Hyvä vaihtoehto noin 5–30 hengen tilaisuuksiin.
            </p>
          </div>
          
          <img src={process.env.PUBLIC_URL + '/images/trick-3.png'}/>
        </div>

        <div>
          <div>
            <h2>Näyttämötaikuus:</h2>
            <p>
              Näyttämötaikuudessa pääset seuraamaan mahtavia esityksiä ja eläytymään tunnelmaan.

              Näyttämötaikuudessa käytetään erilaisia elementtejä niin, että ne erottuvat hyvin isommallekin yleisölle.

              Soveltuu hyvin suurempiin tilaisuuksiin ja juhliin, joissa esityksen on tarkoitus näkyä kerralla kaikille.
            </p>
          </div>
          
          <img src={process.env.PUBLIC_URL + '/images/pricing.png'}/>
        </div>

      </section>

      <section className='home__section home__section__4'>
        <h2>Näin tilaat taikurin:</h2>

        <p>
          1. Jätä tarjouspyyntö.
        </p>
        <p>
          2. Kerro vähän tilaisuudestasi ja suunnittelemme yhdessä sinulle sopivan kokonaisuuden.
        </p>
        <p>
          3. Nauti esityksestä!
        </p>

      </section>

      <section className='home__section home__section__5'>
        <p>
          Haluatko tehdä itsellesi taikatempun kotonasi? Nyt siihen on mahdollisuus. 
          Nappaa korttipakka ja seuraa oheisen videon ohjeita!
        </p>
        <video src="https://taikurisivut.s3.eu-central-1.amazonaws.com/1704468642809_0.5518832644439109_taikuutta-k%C3%A4siss%C3%A4si.mp4" controls></video>
      </section>

      <section id='tarjouspyyntö' className='home__section home__section__6'>
        <h2>Pyydä Tarjous</h2>
        <ContactForm/>
      </section>
      <Footer/>
    </div>
  )
}

export default Home;