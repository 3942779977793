import React from 'react';
import ContactForm from '../Home/ContactForm';
import NavBar from '../../components/NavBar/NavBar';

const ContactPage = () => {

  return (
    <div className='contact-page'>
      <NavBar/>
      <h1 className='contact-page__title'>Pyydä Tarjous</h1>
      <ContactForm/>
    </div>
  );
};

export default ContactPage;