import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import logo from './logo.svg';
import './App.css';
import './main.scss';
import './normalize.css';
import ImageGallery from './pages/ImageGallery/ImageGallery';
import VideoGallery from './pages/VideoGallery/VideoGallery';
import Login from './pages/Admin/Login';
import { AuthProvider } from './auth/AuthContext';
import Admin from './pages/Admin/Admin';
import Footer from './components/Footer/Footer';
import ThankYou from './pages/ThankYou/ThankYou';
import About from './pages/AboutMe/AboutMe';
import Pricing from './pages/Pricing/Pricing';
import ContactPage from './pages/ContactPage/ContactPage';
import ScrollToTop from './components/ScrollToTop';
import UserCreationForm from './components/CreateUser';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/kuvagalleria" element={<ImageGallery/>} />
          <Route path="/videogalleria" element={<VideoGallery/>} />
          <Route path="/tietoa-minusta" element={<About/>}/>
          <Route path="/admin" element={<Admin/>} />
          <Route path="/tarjouspyyntö-lähetetty" element={<ThankYou/>} />
          <Route path="/taikurin-hinta" element={<Pricing/>} />
          <Route path="/tarjouspyyntö" element={<ContactPage/>} />
          <Route path="/createUser" element={<UserCreationForm/>}/>
        </Routes>
        <Footer/>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
