import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router';
import { BASE_URL } from '../config';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a token in local storage
    const token = sessionStorage.getItem('token');
    if (token) {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);

      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Convert to seconds
      if (decodedUser.exp < currentTime) {
        // Token is expired, perform logout
        logout();
      }
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        if (response.status === 404 || response.status === 401) {
          throw new Error('Virheellinen salasana tai sähköposti.');
        } else {
          throw new Error('Palvelinvirhe. Ota ylläpitoon yhteyttä.');
        }
      }
  
      const data = await response.json();
      const token = data.token;

      sessionStorage.setItem('token', token);
      setUser(jwtDecode(token));
      navigate('/admin');
    } catch (error) {
      // Handle network error
      if (error.message === 'Failed to fetch') {
        throw new Error('Palvelinvirhe. Ota ylläpitoon yhteyttä.');
      }
      // Rethrow other errors for handling
      throw error;
    }
  }

  const logout = () => {
    sessionStorage.removeItem('token');
    setUser(null);
    navigate('/');
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}