import { BASE_URL } from "../config";

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    console.log('Backend error: ', errorData.error);
    throw new Error(errorData.error)
  }
  
  const data = await response.json();
  return data;
};

export const getImages = async () => {
  const response = await fetch(`${BASE_URL}/getImages`);
  return handleResponse(response)
};

export const uploadImage = async (formData) => {
  const response = await fetch(`${BASE_URL}/uploadImage`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }, 
    body: formData
  });
  return handleResponse(response);
};

export const deleteImage = async (id) => {
  const response = await fetch(`${BASE_URL}/deleteImage/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
  });
  return handleResponse(response);
};

export const getVideos = async () => {
  const response = await fetch(`${BASE_URL}/getVideos`);
  return handleResponse(response)
};

export const getS3VideoUploadUrl = async (fileName) => {
  const response = await fetch(`${BASE_URL}/getS3VideoUploadUrl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ fileName })
  });
  return handleResponse(response);
};

export const saveVideoDataToDatabase = async (videoData) => {
  const response = await fetch(`${BASE_URL}/saveVideoDataToDatabase`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify({ videoData })
  });
  return handleResponse(response);
};

export const uploadVideo = async (formData) => {
  const response = await fetch(`${BASE_URL}/uploadVideo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: formData
  });
  return handleResponse(response);
};

export const deleteVideo = async (id) => {
  const response = await fetch(`${BASE_URL}/deleteVideo/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  return handleResponse(response);
};

export const getGigRequests = async () => {
  const response = await fetch(`${BASE_URL}/getGigRequests`);
  return handleResponse(response)
}

export const addGigRequest = async (formData) => {
  const response = await fetch(`${BASE_URL}/addGigRequest`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  });
  return handleResponse(response);
};

export const deleteRequest = async (id) => {
  const response = await fetch(`${BASE_URL}/deleteRequest/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  });
  return handleResponse(response);
};

export const verifyCaptcha = async (captchaValue) => {
  const response = await fetch(`${BASE_URL}/verifyCaptcha`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(captchaValue)
  });
  return handleResponse(response);
};
