import React from 'react';
import { useAuth } from '../../auth/AuthContext';
import Login from './Login';
import GigRequests from './GigRequests';

const Admin = () => {
  const { user } = useAuth();

  if (user) {
    return (
    <div className='admin'>
      <GigRequests/>
    </div>
    )
  }

  return (
    <div className='admin'>
      <Login/>
    </div>
  )
}

export default Admin;