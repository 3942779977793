import React, { useState } from 'react';
import { BASE_URL } from '../../config';

const UserCreationForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setError(null);
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/addUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User created successfully:', data);
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error('Error creating user:', error.message);
      setError('Internal server error. Please try again later.');
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        maxWidth: '300px',
        minHeight: '100vh',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Sähköpostiosoite:</label>
        <input type="email" name="email" value={email} onChange={handleInputChange} required style={{ width: '100%' }} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Salasana:</label>
        <input type="password" name="password" value={password} onChange={handleInputChange} required style={{ width: '100%' }} />
      </div>
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      <button
        type="submit"
        style={{ padding: '8px 16px', cursor: 'pointer' }}
      >
        Luo käyttäjä
      </button>
    </form>
  );
};

export default UserCreationForm;
