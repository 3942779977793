import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';

const About = () => {
  return (
    <div className="about-page">
      <NavBar/>
      <h1 className="about-page__title">Tietoa minusta</h1>

      <div className="about-page__content">
        
        <div className="about-page__content__section">
          <img src={process.env.PUBLIC_URL + '/images/about-me-1b.jpg'} className="about-page__content__section__item about-page__content__section__img"/>
          <div className="about-page__content__section__item about-page__content__section__text">
            <h2>Harrastuksesta ammatiksi</h2>
            <p>
              Olen ollut taikuuden parissa jo lapsesta asti, aloittaen harrastuksen vuonna 2003 
              ollessani kuusivuotias. Veljeni salaperäinen taikurisetti kiehtoi minua. Hän yritti 
              piilotella sitä visusti, mutta keksin keinot selvittääkseni taikuuden salaisuudet. 
              Matkani taikurina on ollut täynnä oppimista ja yhteistyötä. Lukuisat mentorit 
              kirjallisuuden parissa ovat avanneet minulle taikuuden maailmaa, ja yhteistyö 
              kollegoiden kanssa on muokannut harrastuksestani ammatin.
              <br/>
              <br/>
              Taikuus lumoaa minut kyvyllään luoda illuusio mahdottomasta. Esiintyjänä minua 
              kiehtoo erilaisten tunnetilojen luominen yleisölle. Pyrin puoleensavetävään 
              esiintymistyyliin, koska haluan luoda taikuuden elämyksiä yhdessä yleisön kanssa. 
              Lapsuudesta asti rakkaus esiintymiseen onkin säilynyt vahvana. Pidän kaikenlaisista 
              taikuuden tyylilajeista, mutta erityisesti naru-, kortti- ja leijutustemput ovat 
              herättäneet kiinnostukseni.
            </p>
          </div>
        </div>
        
        <div id='about-page-section-2' className="about-page__content__section">
          <img src={process.env.PUBLIC_URL + '/images/about-me-2b.jpg'} className="about-page__content__section__item about-page__content__section__img"/>
          <div className="about-page__content__section__item about-page__content__section__text">
            <h2>Mitä tarjoan asiakkaalle?</h2>
            <p>
              Tarjoan monipuolisia esiintymismuotoja, jotka soveltuvat erilaisiin tilaisuuksiin. 
              Olipa kyseessä sitten cocktailtilaisuus, juhlat tai rento saunailta, löydämme yhdessä 
              juuri sinulle sopivan taikuuden muodon.
              <br/>
              <br/>
              Juhliin suosittelen esimerkiksi lavashow'ta, jossa taikuri esittää kiehtovia temppuja 
              huumorin säestyksellä. Jos haluat jotain syvällisempää, mentalismi on erinomainen 
              vaihtoehto. Erilaisiin tilaisuuksiin joustavammaksi vaihtoehdoksi tai lavashow’n 
              lisäksi sopii mainiosti lähitaikuus, joka tarjoaa yleisölle henkilökohtaisempia 
              elämyksiä esimerkiksi pöydästä pöytään kierrellessä.
              <br/>
              <br/>
              Salonkitaikuus puolestaan toimii hyvänä välimuotona pienempiin tilaisuuksiin, 
              yhdistäen lavan ja lähitaikuuden parhaat puolet. Oli tarpeesi mikä tahansa, voimme 
              yhdessä suunnitella juuri sinun tilaisuuteesi sopivan ja ainutlaatuisen 
              esiintymiskokonaisuuden. Keskity vain nauttimaan unohtumattomasta hetkestä yhdessä 
              yleisösi kanssa!
            </p>
          </div>
        </div>
        
        <div className="about-page__content__section">
          <img src={process.env.PUBLIC_URL + '/images/about-me-3b.jpg'} className="about-page__content__section__item about-page__content__section__img"/>
          <div className="about-page__content__section__item about-page__content__section__text">
            <h2>Kokemus</h2>
            <p>
              Kuten aikaisemmin mainitsin, aloitin taikuuden harrastamisen 6-vuotiaana.
              Esiintymisen aloitin 10-vuotiaana. Olin tuolloin mukana perheeni
              järjestämässä käsi- ja nukketeatteriryhmässä, jonka ohella esitin
              myös taikuutta.
              <br/>
              <br/>
              Ammattitaitoni kasvoi ja aloin esiintymään myös itsenäisesti omilla
              keikoilla. Olen toiminut taikurina festivaaleilla Saksassa,
              Torivartti MTV3:ssa, SkillsFinlandin 50-vuotisgaalassa pääesiintyjänä
              sekä ammattioppilaitos WinNovan uuden kampuksen avajaisissa. Lisäksi
              olen ollut esiintymässä erilaisilla markkinoilla ja useissa
              yksityistilaisuuksissa, kuten häissä ja pikkujouluissa.
              Ammattiin opiskelevien kulttuurikisa SAKUstarsissa olen
              voittanut vuosina 2015 ja 2016 talent-osioiden 1. sijat.
              <br/>
              <br/>
              Luodaanko yhdessä uusi kokemus?
              <Link className="about-page__grid__item-6__link" to="/tarjouspyyntö"> Pyydä tarjous!</Link>
            </p>
          </div>
        </div>
        
        <div className="about-page__grid__item-7"></div>
      </div>
    </div>
  );
};

export default About;
