import React from 'react';
import NavCard from './NavCard';
import { Link } from 'react-router-dom';

const NavBar = () => {

  return (
    <div className='nav-bar'>
      <Link className='nav-bar__link' to='/'>
        <img className='nav-bar__logo' src={`${process.env.PUBLIC_URL}/logo.png`} alt='logo'/>
        <div className='nav-bar__container'>
          <p className='nav-bar__container__name'>Taavi Saarva</p>
          <p className='nav-bar__container__subtext'>Taikuutta vuosien kokemuksella!</p>
        </div>
      </Link>
      <NavCard/>
    </div>
  )
}

export default NavBar;