import React, { useState } from 'react';
import { uploadImage } from '../../utils/api';
import Modal from '../../components/Modal/Modal';

const ImageUploader = ({ isOpen, onClose, onUpload }) => {
  const [fileInput, setFileInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('Selected file:', file);
    setFileInput(file);
  };

  const handleUpload = async () => {
    try {
      if (!fileInput) {
        console.error('No file selected for upload');
        return;
      }

    setIsLoading(true);

    const formData = new FormData(); // Create a FormData object
    formData.append('image', fileInput);
    formData.append('originalname', fileInput.name);

      // Perform the image upload
      await uploadImage(formData);

      // Callback to notify the parent component about the upload
      if (typeof onUpload === 'function') {
        onUpload();
      }

      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error uploading image', error.message);
    } finally {
      // Set loading state back to false after upload completes (whether success or failure)
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <p>Muista pienentää kuvakokoa jos tarpeellista!</p>
      <input type='file' onChange={handleFileChange} />
      <div>
        <button onClick={handleUpload} disabled={isLoading}>
          {isLoading ? 'Lisätään...' : 'Lisää kuva'}
        </button>
        <button onClick={onClose}>Peruuta</button>
      </div>
    </Modal>
  );
};

export default ImageUploader;
