import React, { useState } from 'react';
import { deleteRequest } from '../../utils/api';

const RequestDetails = ({ selectedRequest, closeModal, updateRequests }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    const confirmed = window.confirm("Oletko varma?");
    if (confirmed) {
      try {
        setIsLoading(true);
        await deleteRequest(selectedRequest._id);
        updateRequests();
        closeModal();
        console.log('Deleted request');
      } catch (error) {
        console.error('Error deleting request:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className={`modal-container active`}>
        <div className={`modal-content active`}>
          <h2>Tarjouspyynnön tiedot</h2>
          <p><strong>Nimi:</strong> {selectedRequest.firstName} {selectedRequest.lastName}</p>
          <p><strong>Puhelinnumero:</strong> {selectedRequest.phoneNumber}</p>
          <p><strong>Sähköpostiosoite:</strong> {selectedRequest.email}</p>
          <p><strong>Tiivistelmä keikasta:</strong> {selectedRequest.summary}</p>
          <p><strong>Muuta:</strong> {selectedRequest.additionalInfo}</p>
          <div>
            <button className='delete-btn' onClick={handleDelete} disabled={isLoading}>
              {isLoading ? 'Poistetaan...' : 'Poista'}
            </button>
          </div>
          <button className='close-btn' onClick={closeModal} disabled={isLoading}>
            Sulje
          </button>
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
