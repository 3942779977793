import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowLeft, faHome, faImage, faVideo, faUser, faCoins, faHandshake } from '@fortawesome/free-solid-svg-icons';

const NavCard = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  }

  return (
    <>
      <button className='nav-card-btn' onClick={toggleNav}>
        <FontAwesomeIcon icon={faBars} />
      </button>

      <div className={`nav-card ${isNavOpen ? 'nav-card__active' : 'nav-card__not-active'}`}>
        <div className={`nav-card__front ${isNavOpen ? 'nav-card__front__active' : 'nav-card__front__not-active'}`}>
          <ul className='nav-card__front__list'>
            <li>
              <button className='nav-card__front__list__item' onClick={toggleNav}>
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className='nav-card__front__list__item__text'>Sulje</span>
              </button></li>
            <li>
              <NavLink className='nav-card__front__list__item' to="/">
              <FontAwesomeIcon icon={faHome} />
              <span className='nav-card__front__list__item__text'>Etusivu</span>
              </NavLink></li>
            <li>
              <NavLink className='nav-card__front__list__item' to="/kuvagalleria">
              <FontAwesomeIcon icon={faImage} />
              <span className='nav-card__front__list__item__text'>Kuvagalleria</span>
              </NavLink></li>
            <li>
              <NavLink className='nav-card__front__list__item' to="/videogalleria">
              <FontAwesomeIcon icon={faVideo} />
              <span className='nav-card__front__list__item__text'>Videogalleria</span>
              </NavLink></li>
            <li>
              <NavLink className='nav-card__front__list__item' to="/tietoa-minusta">
              <FontAwesomeIcon icon={faUser} />
              <span className='nav-card__front__list__item__text'>Tietoa minusta</span>
              </NavLink></li>
            <li>
              <NavLink className='nav-card__front__list__item' to="/taikurin-hinta">
              <FontAwesomeIcon icon={faCoins} />
              <span className='nav-card__front__list__item__text'>Taikurin hinta</span>
              </NavLink></li>
            <li>
              <NavLink className='nav-card__front__list__item' to="/tarjouspyyntö">
              <FontAwesomeIcon icon={faHandshake} />
              <span className='nav-card__front__list__item__text'>Tee tarjouspyyntö</span>
              </NavLink></li>
          </ul>
        </div>

        <div className={`nav-card__back ${isNavOpen ? 'nav-card__back__active' : 'nav-card__back__not-active'}`}></div>
      </div>
    </>
  );
}

export default NavCard;
