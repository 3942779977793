import { Link } from "react-router-dom";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className='footer'>
      <Link className='footer__link' to='/'>
        <img className='footer__logo' src={`${process.env.PUBLIC_URL}/logo.png`} alt='logo'/>
      </Link>
      <div id="footer__copyright">
        &copy; <span id="current-year">{currentYear}</span> All rights reserved.
      </div>
    </footer>
  )
}

export default Footer;