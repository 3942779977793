import React, { useState } from 'react';
import { useAuth } from '../../auth/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await login(email, password);
    } catch (error) {
      setError(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login'>
      <form className='login__form' onSubmit={handleLogin}>
        <h2>Sisäänkirjautuminen</h2>
        <label htmlFor="email">Sähköpostiosoite:</label>
        <input
          id='email'
          className='login__form__input'
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Salasana:</label>
        <input
          className='login__form__input'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <p className='login__form__error'>{error}</p>}
        
        <button type='submit' disabled={loading}>{loading ? 'Kirjaudutaan...': 'Kirjaudu sisään'}</button>
      </form>
    </div>
  );
};

export default Login;
