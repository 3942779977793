import React, { useEffect, useState } from 'react';
import { deleteImage, getImages } from '../../utils/api';
import { S3_URL } from '../../config';
import NavBar from '../../components/NavBar/NavBar';
import Modal from '../../components/Modal/Modal';
import ImageUploader from './ImageUploader';
import { useAuth } from '../../auth/AuthContext';

const ImageGallery = () => {
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const data = await getImages();
      setImages(data);
    } catch (error) {
      console.error('Error fetching images', error.message);
    }
  };

  const openDeleteModal = (image) => {
    setSelectedImage(image);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedImage(null);
    setIsDeleteModalOpen(false);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteImage(selectedImage._id);
      // Update the state or refetch the images after deletion
      fetchImages();
    } catch (error) {
      console.error('Error deleting image: ', error.message);
      if (error.message === 'Forbidden') {
        alert('Sinulla ei ole oikeuksia tähän toimintoon. Sessio saattaa olla vanhentunut.')
      }
    } finally {
      setIsDeleting(false); // Reset the loading state
      closeDeleteModal();
    }
  };

  return (
    <>
      <NavBar />
      <div className='image-upload-btn-container'>
        {user 
        && <button className='image-upload-btn' onClick={openUploadModal}>
            Lisää Kuva
          </button>
        }
        
      </div>
      <div className='image-gallery'>
        {images.length > 0 ? (
          images.map((image) => (
            <div key={image._id} className='image-gallery__container'>
              {user && (
                <button
                  className='image-gallery__delete-btn'
                  onClick={() => openDeleteModal(image)}
                >
                  Poista
                </button>
              )}
              <img src={`${S3_URL}/${image.imageName}`} alt={image.name} />
            </div>
          ))
        ) : (
          <p>Ladataan kuvia...</p>
        )}
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      >
        <p>Poista kuva?</p>
        <div>
          <button onClick={handleDelete}>{isDeleting ? 'Poistetaan...' : 'Kyllä'}</button>
          <button onClick={closeDeleteModal}>Ei</button>
        </div>
      </Modal>
      <ImageUploader
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        onUpload={fetchImages}
      />
    </>
  );
};

export default ImageGallery;
