import React, { useEffect, useState } from 'react';
import { getGigRequests } from '../../utils/api';
import RequestDetails from './RequestDetails';

const GigRequests = () => {
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const openModal = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };

  const updateRequests = async () => {
    try {
      const updatedData = await getGigRequests();
      setRequests(updatedData);
    } catch (error) {
      console.error('Error updating requests:', error);
    }
  };

  useEffect(() => {
    getGigRequests()
    .then((data) => {
      setRequests(data);
    })
    .catch((error) => {
      console.error(error);
    })
  }, [])

  return (
    <div className='gig-requests'>
      <h1>Tarjouspyynnöt</h1>
      <table>
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Puhelinnumero</th>
            <th>Sähköposti</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {requests.slice().reverse().map((request) => (
            <tr key={request._id} onClick={() => openModal(request)}>
              <td>{request.firstName} {request.lastName}</td>
              <td>{request.phoneNumber}</td>
              <td>{request.email}</td>
              <td><button onClick={() => setSelectedRequest(request._id)}>Tiedot</button></td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedRequest && (
        <RequestDetails 
          selectedRequest={selectedRequest} 
          closeModal={closeModal}
          updateRequests={updateRequests}
        />
      )}
    </div>
  )
}

export default GigRequests;