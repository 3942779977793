import React from 'react';
import NavBar from '../../components/NavBar/NavBar';

const ThankYou = () => {

  return (
    <div className='thank-you-page'>
      <NavBar/>
      <div className='thank-you-page__content'>
        <h2>Kiitos tarjouspyynnöstäsi. Pyrin ottamaan sinuun yhteyttä 24 tunnin sisällä.</h2>
      </div>
    </div>
  )
};

export default ThankYou;